import { zgyxRequest, request } from '@src/utils';
const baseUrlFix = '/cmsapi';

/**
 * 市打分-详情
 * @param {*} params
 * @returns
 */
const apiGetProjectResultByCity = (params = {}) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + '/selection/project-result/info/complete-result/city/evaluate',
    params
  });
};

/**
 * 市打分-详情-查看
 * @param {*} projectResultId
 * @returns
 */
const apiGetProjectResultLookByAll = (projectResultId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/project-result/info/complete-result/view/${projectResultId}`
  });
};
/**
 * 打分保存
 * @param {*} data
 * @returns
 */
const apiPostSaveCityMark = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/city/mark', data });
};

/**
 * 暂存所有
 * @param {*} data
 * @returns
 */
const apiPostTemporaryCity = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/city/mark/temporary', data });
};

/**
 * 市推荐
 * @param {*} data
 * @returns
 */
const apiPostTotalEvaluate = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/city/total-evaluate', data });
};

export {
  apiGetProjectResultByCity,
  apiPostSaveCityMark,
  apiPostTemporaryCity,
  apiPostTotalEvaluate,
  apiGetProjectResultLookByAll
};
